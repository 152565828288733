<template>
  <div class="card border-0 shadow-sm py-4 px-2 rounded-0">
    <div class="card-body">
      <div>
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="fw-normal">{{ title }}</h5>
            <slot name="under-title"></slot>
          </div>
          <slot name="header-right"></slot>
        </div>
        <slot name="header"></slot>
      </div>
      <hr />
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "C-Card",
  props: {
    title: {
      type: String,
    },
  },
};
</script>

<style scoped></style>
