import axios from "@/plugins/Axios";

const model = "users";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}
function create(user) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, user);
}

function update(id, user) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, user);
}

// next method is used by admin
function updateUser(id, user) {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/update`,
    user
  );
}

function updateCompanyLogo(formData) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/company-logo`,
    formData
  );
}

function createPlanOrder(planId) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/create-plan-order`,
    {
      plan_id: planId,
    }
  );
}

function subscribe(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}/subscribe`, data);
}

function cancelSubscription() {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/cancel-subscription`
  );
}

function subscriptions() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/subscriptions`);
}

function addMarcoLegalGeneral(marcoLegalGeneralId) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/marcos-legales-generales`,
    {
      marco_legal_general_id: marcoLegalGeneralId,
    }
  );
}

function removeMarcoLegalGeneral(marcoLegalGeneralId) {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/${model}/marcos-legales-generales/${marcoLegalGeneralId}`
  );
}

function addMarcosLegalesEspecificos(marcosIDs) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/marcos-legales-especificos`,
    {
      marcos_legales_especificos: marcosIDs,
    }
  );
}

function marcosLegalesEspecificos() {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/${model}/marcos-legales-especificos`
  );
}

function updateMarcoLegalEspecificoActions(marcoId, data) {
  return axios.put(
    `${process.env.VUE_APP_API_URL}/${model}/marcos-legales-especificos/${marcoId}`,
    data
  );
}

function toolFiles(toolId) {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/${model}/tool-files/${toolId}`
  );
}

function buyTool(toolId) {
  return axios.get(
    `${process.env.VUE_APP_API_URL}/${model}/buy-tool/${toolId}`
  );
}

function tools() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/tools`);
}

function DBPlan() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/db-plan`);
}

function userPlanIsActive() {
  return new Promise((resolve, reject) => {
    subscriptions()
      .then((response) => {
        if (response.data.name) {
          resolve(response.data.is_active || false);
        }
        resolve(false);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  all,
  find,
  create,
  update,
  updateUser,
  updateCompanyLogo,
  createPlanOrder,
  subscribe,
  cancelSubscription,
  subscriptions,
  addMarcoLegalGeneral,
  addMarcosLegalesEspecificos,
  removeMarcoLegalGeneral,
  marcosLegalesEspecificos,
  updateMarcoLegalEspecificoActions,
  toolFiles,
  buyTool,
  tools,
  DBPlan,
  userPlanIsActive,
};
