<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/usuarios/crear">
        Crear usuario
      </c-primary-button>
    </div>
    <c-card title="Usuarios">
      <table class="table table-striped">
        <thead>
          <tr>
            <th></th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Plan</th>
            <th>Fecha de creación</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, index) in users" :key="user.id">
            <td>{{ index + 1 }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.current_plan_name }}</td>
            <td>{{ new Date(user.created_at).toLocaleDateString() }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/usuarios/${user.id}/editar`"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteUser(user.id)"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import { all } from "@/services/users";
import CPrimaryButton from "@/components/CPrimaryButton.vue";

export default {
  components: { CPrimaryButton, CCard },
  data() {
    return {
      users: [],
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      all().then((response) => {
        this.users = response.data;
      });
    },
    deleteUser(id) {
      const user = this.users.find((user) => user.id === id);
      console.log(user);
      if (user.current_plan_name) {
        alert("No podemos eliminar a un usuario con un plan activo");
        return;
      }
      alert("Eliminamos al usuario con el id " + id);
    },
  },
};
</script>
