<template>
  <router-link v-if="to !== ''" :to="to" class="btn c-btn-primary">
    <slot></slot>
  </router-link>
  <button v-else class="btn c-btn-primary">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "CPrimaryButton",
  props: {
    to: {
      default: "",
      type: String,
    },
  },
};
</script>

<!-- not scopes so we can use the class in other parts-->
<!--<style lang="sass">
.c-btn-primary
  // background-color: #f6464b
  background-color: #0096ff
  color: white!important
</style>-->
