function showNotification(status = 200, type = "info", text = "") {
  if (status === 403) {
    type = "danger";
    text = "No tienes permisos para ejecutar la acción.";
  } else if (status === 500) {
    type = "danger";
    text = "Tuvimos un problema con nuestro servidor.";
  } else if (status === 422) {
    type = "danger";
    text = "Revisa los campos e inténtalo nuevamente.";
  } else if (status === 201) {
    type = "success";
    text = "El registro fue guardado exitósamente.";
  }
  document.getElementById("notification-text").textContent = text;
  const notification = document.getElementById("notification");
  notification.classList.remove(
    "bg-danger",
    "bg-info",
    "bg-success",
    "bg-warning"
  );
  notification.classList.add("show", "bg-" + type);

  setTimeout(() => {
    notification.classList.remove("show");
  }, 5000);
}

export { showNotification };
