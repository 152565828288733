import axios from "axios";
import { showNotification } from "../services/notification";

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("logged");
      window.localStorage.clear();
      window.location.href = "/auth/login";
    } else if (error.response.status === 403) {
      showNotification(403);
    } else if (error.response.status === 500) {
      showNotification(500);
    } else if (error.response.status === 422) {
      showNotification(422);
    } else {
      showNotification(
        null,
        "danger",
        "Tuvimos un problema procesando la petición."
      );
    }
    return Promise.reject(error);
  }
);

export default axios;
